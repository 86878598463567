@charset "utf-8";
/*------Responsive------------------------------------------------*/
@media only screen and  (max-width: 1064px) {
 .nav-button-wrap , .mob-bg{display:block;}
 .main-menu-mobile {position: absolute; top: 80px; margin: 0; width: 200px; z-index: 50; padding: 15px 10px;
    background: #fff; border-bottom-left-radius: 6px; border-left: 1px solid #eee;
    border-bottom: 1px solid #eee;visibility: hidden; opacity: 0; transition: all 0.4s ease-in-out;}
 .main-menu-mobile.active{visibility: visible; opacity:1;}
 .main-menu-mobile nav {display: none;}
 .main-menu-mobile.active nav{display: block;}
 .nav-holder nav li {margin: 0; float: left; width: 100%; position: relative; overflow: hidden; padding: 0 20px; height: 40px;}
 .nav-holder nav li a.act-link:before {display: none;}
 .white-header .nav-holder nav li a {float: left; width: 100%; padding: 8px 5px; position: relative; color: #566985; text-align: left;}
}
@media only screen and  (max-width: 768px) {
 .fs-header {padding: 0px 20px;}
 .show-reg-form {margin-right: 20px;}
}
@media only screen and  (max-width: 540px) {
 header.main-header {padding:0 10px;}
 .show-reg-form {margin-right: 5px;}
}
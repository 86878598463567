@charset "utf-8";
/*-------------Fonts---------------------------------------*/
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800,800i,900|Quicksand:300,400,500,700&subset=cyrillic,cyrillic-ext,latin-ext,vietnamese');
/*-------------General Style---------------------------------------*/
:root {
  --primary_color: #1877F2;
  --secondary_color: #5BA9EF;
  --tertiary_color:#334e6f;
  --light_color: #ffffff;
  --dark_color: #f9f9f9;
  --primary_font: "WorkSans-Regular";
  --secondary_font: "FontAwesome";
}
html{overflow-x:hidden !important; height:100%;}
body{
	margin: 0;
	padding: 0;
	font-weight: 400;
	font-size: 13px;
	height:100%;
	text-align:center;
	background:#2F3B59;
	color:#000;
	font-family: var(--primary_font);
}
@-o-viewport {width: device-width;}
@-ms-viewport {width: device-width;}
@viewport {width: device-width;}
/*--------------Typography--------------------------------------*/
p {
    font-size: 12px;
    line-height: 24px;
    padding-bottom: 10px;
	font-weight:500;
	color: #878C9F;
}
blockquote {
	float:left;
	padding: 10px 20px;
	margin: 0 0 20px;
	font-size: 17.5px;
	border-left: 15px solid #eee;
	position:relative;
}
blockquote:before {
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
	content: "\f10e";
	position:absolute;
	color:#ccc;
	bottom:3px;
	font-size:43px;
	right:6px;
	opacity:0.4
}
blockquote p {
	font-family: Georgia, "Times New Roman", Times, serif;
	font-style:italic;
	color:#494949;
}
/* ---------Content Styles--------------------------------------*/
#main{
	height:100%;
	width:100%;
	position:absolute;
	top:0;
	left:0;
	z-index:2;
	opacity:0;
}
.container{
	max-width: 1224px;
	width:92%;
	margin:0 auto;
	position: relative;
	z-index:5;
}
.big-container {max-width:1500px;}
.small-container {max-width:1024px;}
#wrapper {
	float:left;
	width:100%;
	position:relative;
	z-index:5;
	padding-top:75px;
}
#wrapper.no-padding {padding-top:0;}
.content {
	width:100%;
	position:relative;
	z-index:4;
	float:left;
	background:#fff;
}
.content.fs-slider-wrap {padding-top:80px;}
.fl-wrap {
	float:left;
	width:100%;
	position:relative;
}
.no-bg {background:none;}
.full-height {height:100%;}
.full-width-wrap {width:100%;}
.bg {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:1;
	background-size: cover;
	background-attachment: scroll  ;
	background-position: center  ;
	background-repeat:repeat;
	background-origin: content-box;
}
.respimg {width:100%; height:auto;}
.overlay {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background:#000;
	opacity:0.5;
	z-index:2
}
.box-item {
	float:left;
	width:100%;
	position:relative;
	overflow:hidden;
}
.box-item:before {
	content:'';
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	opacity:0;
	z-index:2;
	background:rgba(51,51,51,0.41);
    transition: all 300ms linear;
}
.box-item a.gal-link{
	position:absolute;
	width:40px;
	height:40px;
	line-height:40px;
	border-radius:100%;
	color:#fff;
	z-index:10;
    transition: all 300ms linear;
}
.fw-separator {
	float:left;
	width:100%;
	margin:25px 0;
	height:1px;
	background:#eee;
}
/*------ section-------------------------------------------------*/
section {
	float:left;
	width:100%;
	position:relative;
	padding:80px 0;
	overflow:hidden;
	background:#fff;
	z-index:1;
}
section.no-top-padding {
	padding:50px 0;
}
section.no-padding {
	padding:0;
}
.header-sec-link {
	position:absolute;
	bottom:0;
	left:0;
	width:100%;
	z-index:20;
}
.header-sec-link a {
	display:inline-table;
	padding:15px 40px;
	border-top-left-radius:6px;
	border-top-right-radius:6px;
	background:#fff;
	font-weight:700;
	color:#fff;
	text-transform:uppercase;
	font-size:12px;
	box-shadow: 0px 0px 0px 4px rgba(255,255,255,0.2);
}
section.color-bg .header-sec-link a{
	color:#000;
	background:#fff !important;
}
section.color-bg .header-sec-link a:hover {
	color:#fff;
}
.header-sec-link a:hover {
	color:#666;
	background:#fff;
}
section.hero-section {
	padding:150px 0 200px;
}
section.hero-section-light {
	padding:50px 0 50px;
}
section.hero-section.no-dadding {
	padding:0;
}
section.gray-section {
	background: #f9f9f9;
}
section.hero-section .bg  {
	height:120%;
	top:-10%;
}
section.parallax-section {
	padding:110px 0;
}
section.video-section {
	padding:150px 0;
}
section.single-par {
	padding:180px 0;
}
section.parallax-section.small-par {
	padding:90px 0;
}
.hero-section-wrap {
	z-index:30;
}
section.parallax-section .bg {
	height:120%;
	top:0;
}
.section-title {
	float:left;
	width:100%;
	position:relative;
	padding-bottom:50px;
}
.section-title .breadcrumbs {
	margin-top:20px;
}
.section-title .breadcrumbs a , .section-title .breadcrumbs  span {
	color:rgba(255,255,255,0.71);
	display:inline-block;
	padding:0 16px;
	position:relative;
	font-weight:500;
}
.section-title .breadcrumbs  span , .section-title .breadcrumbs a:hover {
	color:#fff;
}
.section-title .breadcrumbs a:before {
	content:'';
	position:absolute;
	top:50%;
	right:-4px;
	margin-top:-3px;
	width:6px;
	height:6px;
	border-radius:100%;
}
section.color-bg .section-title .breadcrumbs {
	margin-top:0;
	margin-bottom:10px;
}
section.color-bg .section-title .breadcrumbs a:before {
	background:#fff !important;
}
.section-title p {color:#7d93b2;font-size:14px;}
.section-title h2 {
	float:left;
	width:100%;
	text-align:center;
	color:#566985;
	font-size:34px;
	font-weight:700;
}
.hero-section .intro-item h2 {
	font-size:45px;
}
.parallax-section .section-title h2 {
	font-size:44px;
}
.section-separator {
	float:left;
	width:100%;
	position:relative;
	margin:20px 0;
}
section.gray-section .section-separator {
	background: #f9f9f9;
}
.section-separator:before{
	content:'';
	position:absolute;
	left:50%;
	top:0;
	height:4px;
	margin-left:-25px;
	width:50px;
	border-radius:3px;
}
.section-separator.fl-sec-sep:before {
	left:0;
	margin-left:0;
}
.parallax-section .section-title h2  , .parallax-section .section-title p{color:var(--light_color);}
.parallax-section .section-title.left-align , .parallax-section .section-title.left-align h2{
	text-align:left;
}
.section-subtitle {
	position:absolute;
	width:100%;
	text-align:center;
	left:0;
	top:-20px;
	font-size:64px;
	opacity:0.13;
	z-index:-1;
	text-transform:uppercase;
	font-weight:bold;
}
.parallax-section .section-subtitle{
	color:#fff;
	opacity:0.2;
}
.section-subtitle:before{
	content:'';
	position:absolute;
	bottom:0;
	left:0;
	width:100%;
	height:100%;
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(255,255,255,0.75) 100%);
}
.gray-section .section-subtitle:before{
	content:'';
	position:absolute;
	bottom:0;
	left:0;
	width:100%;
	height:100%;
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(249,249,249,0.75) 100%);
}
.parallax-section .section-subtitle:before {
	display:none;
}
/* ------------------------------Form---------------------------- */
.btn {border-radius: 6px;font-weight: 600;}
.btn.flat-btn{background: var(--primary_color);color: var(--light_color);padding: 15px 40px;}
.btn.color-bg:hover {background: var(--secondary_color);}
.custom-form button {outline: none;border: none;cursor: pointer;margin-top: 30px;}
.btn i {padding-left: 10px;}
.custom-form .filter-tags {float: left;margin-top: 30px;}
.custom-form .filter-tags input {float: left;position: relative;border: 1px solid #ccc;cursor: pointer;
    padding: 0;width: 20px;height: 20px;position: relative;}
.custom-form .filter-tags label {float: none;padding-left: 30px;display: block;}
.custom-form label i {padding-right: 12px;font-size: 14px;position: absolute;top: 16px;left: 20px;}
/* ------------------------------General utility---------------------------- */
.nopadding {padding:0;}
.topbot50padding {padding: 50px 0}
.bot25padding {padding-bottom: 25px}
.bot50padding {padding-bottom: 50px}
.nomargin {margin:0;}
.background-primary {background-color: var(--primary_color);}
.background-secondary {background-color: var(--secondary_color);}
.background-dark {background-color: var(--dark_color);}
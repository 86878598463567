/* ------------------------------Section---------------------------- */
.main-search-input-wrap {max-width: 900px;margin: 20px auto;position: relative;}
.main-search-input {background:var(--light_color);padding:0 120px 0 0;border-radius:30px;margin-top:50px;
 box-shadow: 0px 0px 0px 10px rgba(255,255,255,0.3);}
.main-search-input-item {float:left;width:100%;box-sizing:border-box;border-right:1px solid #eee;
 height:50px;position:relative;}
.main-search-input-item input:first-child {border-radius: 100%;}
.main-search-input-item input {float:left;border:none;width:100%;height:50px;padding-left:20px;}
.main-search-button {position:absolute;right:0px;height:50px;width:120px;color:var(--light_color);
 top:0;border:none;border-top-right-radius:30px;border-bottom-right-radius:30px;cursor:pointer;}
 /* ------------------------------Section search---------------------------- */
.left-list {float: left;}
.fh-col-list-wrap{width:100%;}
.col-list-wrap {position:relative;background:#f9f9f9;min-height: 100vh;padding-top: 50px;}
.listsearch-header {padding-bottom: 15px;border-bottom: 1px solid #eee;}
.listsearch-header h3 {float: left;text-align: left;font-size: 22px;font-weight: 700;
 color: #5D6275;}
.listing-view-layout {float: right;}
.listing-view-layout li {float: left;margin-left: 10px;}
.listing-view-layout li  a {width:40px;height:40px;line-height:40px;float:left;cursor: pointer;
 border:1px solid var(--light_color);border-radius:100%;color:var(--light_color);}
.listing-view-layout li a i {font-size: 14px;}
.listing-view-layout li a.active {background: var(--light_color) !important;}
.list-main-wrap {padding: 50px 0;}
.listing-item {float: left;width: 50%;padding: 0 8px;margin-bottom: 12px;}
.listing-item.list-layout {width: 100%;padding: 0;background: var(--light_color);}
.listing-item.list-layout .geodir-category-img {overflow: hidden;}
.listing-item.list-layout .geodir-category-img , .listing-item.list-layout .geodir-category-content {width:50%;float:left;position:relative;}
.listing-item.list-layout .listing-geodir-category {left: -100%;margin-left: 20px;top: 20px;z-index: 100;}
.listing-item.list-layout .listing-avatar {top: 85%;margin-top: -40px;margin-right: 20px;right: 100%;}
.card-listing .geodir-category-listing {overflow: hidden;border-radius: 10px;border: 1px solid #eee;}
.card-listing .geodir-category-img img {float: left;width: 100%;height: auto;z-index: 1;}
.geodir-category-img img {transform: translateZ(0);transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;}
.card-listing .geodir-category-img .overlay {opacity: 0.1;}
.card-listing .geodir-category-content {padding: 30px 20px 10px;background: var(--light_color);}
.card-listing .geodir-category-content {z-index: 2;}
.card-listing .listing-geodir-category {position: absolute;top: -20px;left: 20px;padding: 10px 25px;
 color: var(--light_color);border-radius: 4px;z-index: 5;box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.4);}
.card-listing .listing-avatar {position: absolute;top: -30px;right: 20px;width: 60px;height: 60px;z-index: 10;}
.card-listing .listing-avatar img {width: 60px;height: 60px;border: 6px solid rgba(255, 255, 255, 0.4);border-radius: 100%;}
.avatar-tooltip {position: absolute;top: -50px;padding: 15px 15px 15px 4px;border-radius: 4px;text-align: right;
 background: var(--light_color);z-index: 10;right: 0;visibility: hidden;pointer-events: none;opacity: 0;margin-top: -20px;min-width: 200px;transition: all 200ms linear;}
.avatar-tooltip strong {font-weight: 700;color: #666;}
.avatar-tooltip:after {border-color: transparent;border-top-color: var(--light_color);border-width: 7px;margin-left: 7px;}
.avatar-tooltip:after {top: 100%;right: 22px;border: solid transparent;content: " ";height: 0;
 width: 0;position: absolute;pointer-events: none;}
.card-listing .geodir-category-content h3 {float: left;width: 100%;margin-bottom: 10px;text-align: left;font-size: 18px;font-weight: 600;}
.card-listing .geodir-category-content h3 a {color: #334e6f;}
.card-listing .geodir-category-content p {text-align: left;font-size: 12px;color: #666;}
.card-listing .geodir-category-location {padding: 15px 0;float: left;width: 100%;margin-top: 15px;font-weight: 500;border-top: 1px solid #eee;}
.card-listing .geodir-category-location a {color: #999;position: relative;}
.card-listing .geodir-category-location a {float: left;}
.card-listing .listing-geodir-category {background-color: #bc4565;}
input.error {color:red;}
 /* ------------------------------Pagination---------------------------- */
.pagination {margin-bottom: 20px;text-align: center;float: left;width: 100%;}
.pagination button {display: inline-block;width: 44px;height: 44px;line-height: 44px;border-radius: 6px;border: 1px solid #eee;box-sizing: border-box;
  position: relative;font-size: 13px;color: #888DA0;background: #fff;letter-spacing: 1px;margin: 2px;}
.pagination button.current-page, .pagination button:hover {color: var(--light_color);}
.pagination button:hover, .pagination button.current-page {background: var(--secondary_color) !important;}

@charset "utf-8";
.main-header {position:fixed; top:0; left:0; width:100%; z-index:100; box-shadow: 0 0 8px 0 rgba(0,0,0,0.12); padding:0 0 6px 0; transform: translate3d(0,0,0); height:75px;}
.center-header .header-inner{max-width:1224px; margin:0 auto; position:relative;}
.white-header {background: var(--light_color);}
.fs-header {padding:0px 50px;}
.logo-holder {float:left; position:relative; top:15px; height:40px;}
.logo-holder img {height:100%; width:auto;}
.show-reg-form {float:right; cursor:pointer; position:relative; top:27px; font-family: var(--primary_font); font-size:17px; font-weight:600;}
.white-header .show-reg-form {color:#000000;}
.show-reg-form:before {
	content:'';
	position:absolute;
	top:50%;
	right:85px;
	height:30px;
	width:1px;
	margin-top:-15px;
	background:rgba(255,255,255,0.41);
}
.white-header .show-reg-form:before { color:#fff; background:rgba(255,255,255,0.21);}
.show-reg-form i {padding-right:10px;}
.header-user-menu {
	float:right;
	position:relative;
	top:20px;
	margin-left:50px;
	margin-right:40px;
}
.header-user-menu:after {
	content:'';
	position:absolute;
	left:-70px;
	height:30px;
	top:4px;
	width:1px;
	background:rgba(255,255,255,0.21);
}
.header-user-name {
	position:relative;
	float:left;
	cursor:pointer;
	color:#fff;
	transition: 0.2s;
    white-space: nowrap;
    text-overflow: ellipsis;
	padding-top:10px;
	font-weight:600;
}
.header-user-name:before {
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
	content: "\f0d7";
	position:absolute;
	color:#fff;
	bottom:1px;
	font-size:13px;
	right:-16px;
	transition: all .2s ease-in-out;
}
.header-user-name span {
	position:absolute;
	width:38px;
	height:38px;
	border-radius:100%;
	overflow:hidden;
	top:0;
	left:-50px;
}
.header-user-name span img {width:100%;height:100%;}
.header-user-menu ul {
	margin: 10px 0 0 0;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    min-width: 150px;
    top: 60px;
    left: -50px;
    z-index: 1;
    padding: 10px 0;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #eee;
    transition: all .2s ease-in-out;
}
.header-user-name.hu-menu-visdec:before {
	-webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
}
.header-user-menu ul.hu-menu-vis {
	opacity: 1;
    visibility: visible;
	 top: 40px;
}
.header-user-menu ul li {
	float:left;
	width:100%;
	padding:4px 0;
}
.header-user-menu ul li a {
    color: #50596E;
    float: left;
    width: 100%;
    font-weight: 500;
    text-align: left;
    padding: 6px 15px;
}
/* ------Navigation------------------------------------------------------------ */
.nav-holder {
	float:right;
	position:relative;
	top:15px;
	margin-right:30px;
	opacity:1;
	right:0;
	visibility:visible;
}
.nav-holder nav {position:relative; float: right;}
.nav-holder nav li{
	float:left;
	position:relative;
	margin-left:6px;
 	height:70px;
}
.nav-holder nav li ul {
	margin: 30px 0 0 0;
	opacity: 0;
	visibility: hidden;
	position: absolute;
	min-width:150px;
	top: 50px;
	left: 0;
	z-index: 1;
	padding:10px 0;
	background:#fff;
	border-radius:6px;
	border:1px solid #eee;
	transition: all .2s ease-in-out;
}
.nav-holder nav li:hover > ul {
	opacity: 1;
	visibility: visible;
	margin: 0;
}
.nav-holder nav li ul li ul {
	top: -10px  ;
	left: -100%;
	margin-left:25px;
	margin-top:0;
	max-width:150px;
}
.nav-holder nav li ul li:hover  > ul  {
	opacity: 1;
	visibility: visible;
	margin-right:0px;
}
.nav-holder nav li ul li {
	width:100%;
	float:left;
	height:auto;
	position:relative;
}
.nav-holder nav li a {
	float: left;
	padding: 10px;
    font-size: 17px;
    font-style: normal;
    font-family: var(--primary_font);
    font-weight: 600;
    text-transform: none;
    line-height: 25px;
    letter-spacing: 0px;
    color: #494949;
    transition: all 100ms linear;
}
.white-header .nav-holder nav li a  {
	color:#000000;
}
.nav-holder nav li a:before {
	content:'';
	position:absolute;
	bottom:10px;
	left:0;
	right:0;
	height:2px;
	border-radius:4px;
	display:none;
}
.nav-holder nav li a.act-link:before {
	display:block;
}
.nav-holder nav li a i {
	padding-left:6px;
    transition: all 200ms linear;
}
.nav-holder nav li a:hover i {transform: rotateX(180deg);}
.nav-holder nav li ul a {
	color:#566985;
	float:left;
	width:100%;
	font-weight:500;
	text-align:left;
	padding:5px 15px;
}
.white-header .nav-holder nav li ul a{color:#000;}
nav li ul a:before , nav li ul a:after {display:none;}
.nav-button-wrap {
	float:right;
	height:40px;
	width:40px;
	cursor:pointer;
	position:relative;
	border-radius:4px;
	top:15px;
	margin-right:15px;
	display:none;
	background: var(--primary_color);
}
.nav-button  {
	position:absolute;
	top:50%;
	left:50%;
	width:22px;
	height:20px;
	margin-left:-11px;
	margin-top:-6px;
}
.nav-button span{
	float:left;
	width:100%;
	height:2px;
	background:#fff;
	margin-bottom:4px;
	border-radius:4px;
    transition: all 200ms linear;
}
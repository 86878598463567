@charset "utf-8";
footer.main-footer {
	float:left;
	width:100%;
	position:relative;
	padding:0;
	z-index:50;
	overflow: hidden;
}
.height-emulator{z-index:1;}
.dark-footer {background:#2C3B5A;}
.footer-widget h3 {
	font-size:17px;
	float:left;
	text-align:left;
	width:100%;
	margin-bottom:30px;
	color:rgba(255,255,255,0.71);
	font-weight:900;
	border-bottom:1px solid rgba(255,255,255,0.11);
	padding-bottom:25px;
}
.sub-footer {
	margin-top:0px;
	background:#24324F;
	padding:15px 0;
}
.copyright {
	display:inline-block;
	text-align: center;
	color: var(--light_color);
	position:relative;
	font-size:13px;
	position:relative;
	top:6px;
	font-weight:500;
	font-family: var(--primary_font);
}
.footer-social {float:right;}
.footer-social li {float:left; margin-left:20px;margin-top: 5px;}
.footer-social li a{float:left;border-left:none;font-size:16px;color: var(--light_color) !important;}
.footer-social li a:hover {color:#999;}
.footer-menu {margin-top:20px;}
.footer-menu  li {
	float:left;
	text-align:left;
	padding-right:16px;
	position:relative;
}
.footer-menu  li:before {
	content:'';
	position:absolute;
	top:50%;
	margin-top:-1px;
	width:4px;
	height:4px;
	right:6px;
	border-radius:100%;
}
.footer-menu  li:last-child:before {
	display:none;
}
.footer-menu  li a {
	color:#eee;
	font-size:10px;
	font-weight:500;
	letter-spacing:2px;
	text-transform:uppercase;
	font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
}
.footer-menu  li a:hover {
	color:rgba(255,255,255,0.41);
}
#footer-twiit img , p.interact , #footer-twiit div.user {
	display:none;
}
#footer-twiit {
	text-align:left;
}
#footer-twiit a {
	color:#fff;
}
#footer-twiit p.tweet {
	font-size:12px;
	color:#999;
	float:left;
	padding:6px 12px;
	background:rgba(255,255,255,0.11);
	border-radius:6px;
	overflow:hidden;
	font-weight:700;
	margin-bottom:10px;
}
#footer-twiit  .timePosted a {
	color:#ccc;
 	font-family: Georgia, "Times New Roman", Times, serif;
	font-style:italic;
	position:relative;
	float:left;
	margin-bottom:30px;
	padding-left:20px;
}
#footer-twiit  .timePosted a:before {
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
	content: "\f099";
	position:absolute;
	top:0;
	left:0;
	color:#ccc;
	opacity:0.7;
	font-size:14px;
}
#footer-twiit  .timePosted a:after {
	top: -10px;
	left: 22px;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}
#footer-twiit  .timePosted a:after {
	border-color: transparent;
	border-top-color: rgba(255,255,255,0.11);
	border-width: 7px;
	margin-left: 7px;
}
.subscribe-widget p {
	color:rgba(255,255,255,0.71);
	text-align:left;
}
.subscribe-widget {
	border-bottom:1px  dashed rgba(255,255,255,0.21);
	padding-bottom:20px;
	margin-bottom:10px;
}
.footer-widget #subscribe {
	float:left;
	width:100%;
}
.footer-widget #subscribe .enteremail {
	float:left;
	width:100%;
	background:#fff;
	height:40px;
	line-height:40px;
	border-radius:4px;
	padding:0 15px;
	box-sizing:border-box;
	border:1px solid rgba(255,255,255,0.21);
	margin-top:20px;
}
.footer-widget #subscribe-button {
	float:right;
	border:none;
	border-radius:4px;
	width:100%;
	height:40px;
	line-height:40px;
	color:#fff;
	margin-top:10px;
	font-weight:600;
	cursor:pointer;
}
.footer-widget #subscribe-button:hover {
	color:rgba(255,255,255,0.71);
}
.footer-widget #subscribe-button:hover i {
	color:rgba(255,255,255,1.0);
}
.subscribe-message {
	color:#fff;
	float:left;
	margin-top:10px;
	text-align:left;
	font-weight:500;
	line-height:19px;
}
.subscribe-message i {
	padding-right:6px;
}
.subscribe-message.valid i{
	color:#5ECFB1;
}
.subscribe-message.error i{
	color:#F00;
}
.footer-widget #subscribe-button i {
	padding-right:6px;
}
.sub-footer .about-widget img {
	float:left;
	width:140px;
	height:auto;
}
.footer-widget .about-widget h4 {
 	font-family: Georgia, "Times New Roman", Times, serif;
	font-style:italic;
	color: #bcbcbc;
	padding-top:6px;
	font-size:17px;
	text-align:left;
	width:100%;
	float:left;
	margin-bottom:12px;
}
.footer-widget .footer-contacts-widget p {
	color:#fff;
	text-align: left;
}
.footer-widget .about-widget p {
	border-bottom:1px solid rgba(255,255,255,0.21);
}
.footer-widget .about-widget .btn {
	float:left;
	margin-top:20px;
	padding:13px 20px;
	border-radius:20px;
	color:#fff;
	font-weight:600;
}
.footer-contacts {
	margin-top:15px;
	padding-top:15px;
	border-top:1px  dashed rgba(255,255,255,0.21);
}
.footer-contacts li {
	float:left;
	width:100%;
	text-align:left;
	padding:10px 0;
	font-size:13px;
	font-weight:500;
}
.footer-contacts li  a , .footer-contacts li span {
	color:rgba(255,255,255,0.51);
}
.footer-contacts li  a {
	color:rgba(255,255,255,0.81);
	padding-left:4px;
}
.footer-contacts li i {
	padding-right:4px;
}
.footer-widget .widget-posts li {
	padding: 13px 0;
	margin-bottom:8px;
}
.footer-widget .widget-posts-img {
	float:left;
	width:30%;
}
.footer-widget .widget-posts-img img {
	border-radius:4px;
}
.footer-widget .widget-comments-img  img{
	 border-radius:100%;
}
.footer-widget .widget-posts-descr{
	float:left;
	width:70%;
	padding-left:20px;
	text-align:left;
	position:relative;
}
.footer-widget .widget-posts-descr:before {
	content:'';
	position:absolute;
	bottom:-12px;
	left:20px;
	right:0;
	height:1px;
	border-bottom:1px  dashed rgba(255,255,255,0.21);
}
.footer-widget .widget-posts-descr a{
	font-size:12px;
	color: rgba(255,255,255,0.71);
	font-weight:600;
	padding-bottom:20px;
}
.footer-widget .widget-posts-descr a:hover {
	color:#fff;
}
.footer-widget .widget-posts-date  {
	float:left;
	width:100%;
	margin-top:10px;
	color:#999;
	font-weight:600;
	font-size:12px;
}
.footer-widget .widget-posts-date i {
	color: rgba(255,255,255,0.41) !important;
	padding-right:10px;
}
.about-wrap {
	margin-bottom:30px;
}
.about-wrap .list-single-main-item-title  h3 {
	font-size:24px;
}
.about-wrap p , .about-wrap h4{
	text-align:left;
}
.about-wrap h4 {
	font-size:16px;
	padding:10px 0 0;
	font-weight:600;
	color:#ADC7DD;
}
.about-wrap .section-separator {
	margin-bottom:5px;
}
@charset "utf-8";
/* ------------------------------Section---------------------------- */
.list-single-main-item {padding: 30px 30px;background: var(--light_color);border-radius: 10px;border: 1px solid #eee;
 margin-bottom: 20px;}
.list-single-main-item-title {margin: 0 0 20px 0;}
.list-single-main-item-title h3 {color: var(--tertiary_color);text-align: left;font-size: 18px;font-weight: 600;}
.box-widget-item {margin-bottom: 30px;}
.box-widget-item-header {display: block;margin-bottom: 22px;}
.box-widget-item-header h3 {text-align: left;font-size: 18px;font-weight: 600;color: var(--tertiary_color);}
.box-widget {background: var(--light_color);border-radius: 10px;border: 1px solid #eee;overflow: hidden;
 float: left;width: 100%;}
.box-widget-content {float: left;width: 100%;position: relative;padding: 25px 30px 30px;}
.list-author-widget-contacts.list-item-widget-contacts li {border-bottom: 1px solid #eee;padding-bottom: 15px;}
.list-author-widget-contacts li {float: left;width: 100%;margin-bottom: 15px;}
.list-author-widget-contacts li span {min-width: 80px;}
.list-author-widget-contacts li span, .list-author-widget-contacts li a {float: left;text-align: left;font-weight: 500;}
.list-author-widget-contacts li a {margin-left: 20px;color: #878C9F;}
.list-author-widget-contacts li span i {margin-right: 7px;font-size: 14px;}
.list-widget-social, .list-widget-social li {float: left;}
.list-widget-social {margin-top: 20px;}
.list-widget-social li {margin-right: 8px;}
.list-widget-social li a {width: 36px;height: 36px;display: block;line-height: 36px;border-radius: 4px;
 color: var(--light_color);}
.shapes-bg-big:before {background-image: url(../images/shapes-big.png);}
.shapes-bg-big:before {content: '';position: absolute;top: 0;left: 0;width: 100%;height: 100%;
 background-repeat: repeat;opacity: 0.3;}
.contacts-avatar {width: 150px;height: 150px;overflow: hidden;display: inline-block;margin: 25px 0 15px;
 border-radius: 100%;box-shadow: 0px 0px 0px 7px rgba(255,255,255,0.2);}
.contacts-avatar img {width: 150px;height: 150px;}
section.background-secondary .header-sec-link a {color: #000;background: var(--light_color) !important;}
#secForm .message {float:left;width:100%;margin-bottom:20px;text-align:center;color:green;font-size: 20px;font-weight: 600;}
#secForm .message.error {color:red;}
.custom-form input.error, textarea.error {color:red;border:1px solid red;}
.custom-form label.error  {color:red;}
@charset "utf-8";
/* ------------------------------Section Questions---------------------------- */
.cirle-bg {position:absolute;right:10%;width: 500px;height: 500px;bottom: -70%;z-index: 1;opacity: 0.1;}
.cirle-bg .bg {background-image: url(../images/circle.png);}
.join-wrap {padding: 10px 0 10px 30px;border-left: 1px solid rgba(255,255,255,0.21);}
.join-wrap h3 {float: left;width:100%;text-align:left;font-size:34px;font-weight: 700;padding-bottom: 20px;color:var(--light_color);}
.join-wrap p {text-align:left;color: rgba(255,255,255,0.51);font-size: 15px;}
.join-wrap-btn {display:inline-table;padding:20px 35px;text-transform: uppercase;background:var(--light_color);border-radius: 30px;
    font-size: 11px;font-weight: 700;position: relative;top: 15px;box-shadow: 0px 0px 0px 7px rgba(255,255,255,0.4);min-width: 150px;}
.join-wrap-btn i {padding-left: 6px;font-size: 13px;}

@media only screen and  (max-width: 1064px) {
	.join-wrap p {padding-bottom: 10px}
	.join-wrap-btn {display: block;float: left;}
}
@media only screen and  (max-width: 768px) {
 .join-wrap {padding-left:0px;border:0;}
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) {
	.bg {background-attachment: scroll !important;-webkit-background-size: cover;background-position: center;}
}
@charset "utf-8";
/* ------------------------------Section---------------------------- */
.accordion {float: left;width: 100%;position: relative;}
.accordion a.toggle.act-accordion {color: var(--light_color);border-color: transparent;cursor: help;}
.accordion a.toggle {border: 1px solid #eee;padding: 18px 30px;float: left;width: 100%;position: relative;cursor: help;
 border-radius: 4px;box-sizing: border-box;text-align: left;font-weight: 700;font-size: 13px;transition: all 300ms linear;margin-bottom: 15px;}
.accordion a.toggle.act-accordion i {transform: rotateX(180deg);} 
.accordion a.toggle i {position: absolute;right: 24px;top: 17px;font-size: 16px;}
.accordion-inner.visible {display: block;}
.accordion-inner {display: none;float: left;width: 100%;position: relative;padding: 15px 30px;
 background: var(--light_color);margin: 0 0 15px;text-align: left;}
 .accordion-inner p {text-align: justify;}
@charset "utf-8";
@media only screen and  (max-width: 1064px) {
/* ------------------------------Section How it works---------------------------- */
.process-wrap li {padding: 25px 20px;}
}
@media only screen and  (max-width: 768px) {
/* ------------------------------Section Welcome---------------------------- */
.intro-item h2 {font-size: 32px;}
.bg {background-image: url(../images/presentazione_molto_utile_mobile.webp);}
/* ------------------------------Section How it works---------------------------- */
.process-wrap li {width: 100%;}
}
@media only screen and  (max-width: 540px) {
/* ------------------------------Section Welcome---------------------------- */
.intro-item h2 {font-size: 30px;}
/* ------------------------------Section How it works---------------------------- */

}
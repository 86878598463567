@charset "utf-8";
/* ------------------------------Section Welcome---------------------------- */
.intro-item h2 {font-size:34px;color: var(--light_color);font-weight:700;padding-bottom:20px;}
.list-single-facts {margin: 10px 0 20px;padding: 20px 30px;border-radius: 6px;}
.list-single-facts .inline-facts-wrap {width: 50%;float: left;color: #fff;position: relative;}
.list-single-facts .inline-facts-wrap h6{color: var(--light_color);font-size: 18px;font-weight: 600;margin-top: 15px;}
.list-single-facts .inline-facts-wrap a {display: inline-block;font-size: 30px;width: 80px;height: 80px;line-height: 65px;
    border-radius: 100%;border: 1px solid rgba(255, 255, 255, 0.21);background: var(--primary_color);}
.list-single-facts .inline-facts img {height: 40px;}
.bg {background-image: url(../images/presentazione_molto_utile.webp);background-position: top;}
/* ------------------------------Section How it works---------------------------- */
.process-wrap li {float: left;width: 33.3%;padding: 25px 40px;position: relative;}
.process-wrap li i {margin: 0 auto 30px;font-size: 59px;}
.process-item {float: left;width: 100%;position: relative;border: 1px solid #eee;
    padding: 40px 30px;border-radius: 6px;z-index: 20;background: #fff;overflow: hidden;}
.process-count {position: absolute;z-index: 1;font-size: 84px;font-weight: bold;left: -20px;top: -10px;opacity: 0.1;color: #666;}
.time-line-icon {position: relative;margin-bottom: 30px;background: url(../images/clouds.png) no-repeat center;}
.process-wrap li h4 {text-align: center;float: left;width: 100%;margin-bottom: 20px;font-weight: 600;font-size: 18px;color: #566985;}
.process-wrap p {text-align: center;color: #999;font-size: 13px;}
.pr-dec {position: absolute;top: 50%;right: -50px;width: 150px;height: 1px;border-bottom: 1px solid #eee;}
.process-end {display: inline-block;margin-top: 30px;position: relative;}
.process-end:before {content: '';position: absolute;top: -60px;left: 50%;width: 1px;height: 70px;background: #eee;z-index: -1;}
.process-end i {width: 60px;height: 60px;line-height: 60px;z-index: 3;background: #5ECFB1;font-size: 20px;
    color: #fff;border-radius: 100%;animation: pulse 2.1s infinite ease-in-out;}

@charset "utf-8";
/* ------------------------------Section---------------------------- */
.label-radio{display:block;position:relative;padding-left:30px;cursor:pointer;font-size:16px;margin-bottom:20px;
 -webkit-user-select:none;-moz-user-select: none;-ms-user-select: none;user-select: none;}
.label-radio input {position: absolute;opacity: 0;cursor: pointer;}
.checkmark {position: absolute;top: 0;left: 0;height: 20px;width: 20px;background-color: #eee;border-radius: 50%;}
.label-radio:hover input ~ .checkmark {background-color: #ccc;}
.label-radio input:checked ~ .checkmark {background-color: var(--primary_color)}
.checkmark:after {content: "";position: absolute;display: none;}
.label-radio input:checked ~ .checkmark:after {display: block;}
.label-radio .checkmark:after {top:6px;left:6px;width:8px;height:8px;border-radius: 50%;background: white;}
#contact-form .message {float:left;width:100%;margin-bottom:20px;text-align:center;color:green;font-size: 20px;font-weight: 600;}
#contact-form .message.error {color:red;}
.custom-form input.error {color:red;border:1px solid red;}
.custom-form label.error  {color:red;}
/* ------------------------------Section Mobile---------------------------- */
@media (max-width: 768px) {
 .list-single-main-wrapper {margin-bottom: 50px;}
}
@charset "utf-8";
.main-register-wrap {position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 1000;
    overflow: auto;display: none;-webkit-transform: translate3d(0,0,0);background: rgba(0,0,0,0.81);}
.main-register-holder {max-width: 480px;margin: 100px auto 50px;position: relative;z-index: 5;}
.main-register {float: left;width: 100%;position: relative;padding: 0 0 30px;margin-bottom: 50px;
    background: #fff;border-radius: 6px;box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.12);}
.close-reg {position: absolute;top: 14px;right: 30px;border-radius: 100%;color: #fff;width: 40px;
    height: 40px;line-height: 40px;cursor: pointer;transition: all .2s ease-in-out;}
.modal.disable{display: none;}
.modal.active{display: block;}
.main-register h3 {float: left;width: 100%;text-align: left;font-size: 20px;padding: 20px 30px;margin-bottom: 20px;
	font-weight: 600;color: #666;border-bottom: 1px solid #eee;}
#tabs-container {float: left;width: 100%;padding: 0 30px;}
.tabs-menu {float: left;width: 100%;margin-top: 20px;margin-bottom: 30px;border-bottom: 1px solid #eee;}
.tabs-menu li {float: left;margin-right: 20px;}
.tabs-menu li.current a, .tabs-menu li a:hover {color: #fff;}
.tabs-menu li a {float: left;padding: 14px 35px;font-weight: 500;color: #494949;background: #eee;border-radius: 3px 3px 0 0;}
.tab {float: left;width: 100%;}
#tab-1 {display: block;}
.tab-content {width: 100%;padding: 20px 0;display: none;}
.custom-form {float: left;width: 100%;position: relative;}
.main-register .custom-form label {padding-bottom: 12px;}
.custom-form label {float: left;position: relative;width: 100%;text-align: left;font-weight: 500;color: #666;}
.custom-form input {margin-bottom: 20px;}
.main-register .custom-form input[type="text"] , .main-register .custom-form  input[type=email] ,
	.main-register .custom-form  input[type=password]{padding-left:20px;} 
.custom-form .log-submit-btn {float: left;padding: 13px 35px;border: none;border-radius: 4px;color: #fff;cursor: pointer;-webkit-appearance: none;margin-top: 12px;}
.lost_password {margin-top: 36px;float: right;}
.lost_password a {float: left;color: #50596E;font-size: 12px;font-weight: 600;}
